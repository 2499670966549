/**********************************************
 ■ SERVER API URL
 **********************************************/
// Local
 //export const SERVER_URL = "https://localhost:8443";
 //export const WSS_URL = "wss://localhost:8443/api";
 //export const SERVER_URL = "http://localhost:8080";
 //export const WSS_URL = "wss://localhost:8080/api";

// Server
export const SERVER_URL = "https://chat.neodigm.com";
export const WSS_URL = "wss://chat.neodigm.com/api";