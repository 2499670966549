import React, {useCallback, useEffect, useRef, useState} from 'react'
import SockJS from 'sockjs-client'
import * as StompJs from '@stomp/stompjs'
import {
  Container,
  BusinessLink,
  Wrapper,
  MessageContainer,
  MessageInputContainer,
  MessageWrapper,
  CenterBox, MotionBase
} from './ChatRoom.style'
import Chatting from "./Chatting"
import Publish from "./Publish"
import ClearChatting from "./ClearChatting"
import ChattingInfo from "./ChattingInfo"
import {
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import {SERVER_URL, WSS_URL} from '../../routes.js'
import Notice from "./Notice";
import {
  useGetChatroomEnterQuery
} from "../../features/chatroom/chatroomApiSlice";
import {Box, CircularProgress} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {logOut, selectCurrentToken} from "../../features/auth/authSlice";
import Typography from "@mui/material/Typography";
import useInterval from "../../utils/useInterval";
import dayjs from "dayjs";
import {elementScrollIntoView} from "seamless-scroll-polyfill";
import {
  useGetHealthAuthQuery
} from "../../features/auth/authApiSlice";
import api from "../../app/api";

function ChatRoomEnter() {
  const client = useRef({});
  const navigate = useNavigate();

  /* Init */
  const [searchParams] = useSearchParams();
  const {roomId} = useParams();
  const scrollRef = useRef();
  const userId = searchParams.get("userId");
  const username = searchParams.get("username");
  const company = searchParams.get("company");

  const [nowTime, setNowTime] = useState();
  const [endTime, setEndTime] = useState();

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectCurrentToken);
  /* 관리자 권한인경우 유효한 토큰인지 체크 */
  const {
    data: auth,
    isSuccess: authIsSuccess
  } = useGetHealthAuthQuery(null, {skip: (isAuthenticated === null)});

  useEffect(() => {
    /* 로그인한 유저의 토큰 검사 후 기간이 지났다면 로그인 요청 */
    if (authIsSuccess && !auth) {
      dispatch(logOut());
      navigate({pathname: '/', search: '?error=token'});
    }
  });

  const [userEnterList, setUserEnterList] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [userDevice, setUserDevice] = useState();

  /* Notice Init */
  const [notice, setNotice] = useState({
    id: null,
    message: "궁금하신 점은 '[질문]내용'으로 채팅창에 올려주세요."
  });

  /*
  * Stomp Init
  * Stomp 연결하는 역할을 합니다.
  * 혹은 채팅 내용을 기록합니다.
  * */
  const [chatting, setChatting] = useState([]);

  /* 접속자의 정보가 부족한경우 접근 불가 */
  const skip = !(roomId !== null && userId !== null && username !== null);

  /* ChatRoomEnter Init */
  const {
    data: chatroom,
    isSuccess
  } = useGetChatroomEnterQuery({
    id: roomId,
    patch: {
      userId: userId
    }
  }, {skip: skip});

  const connect = useCallback(async () => {
    client.current = new StompJs.Client({
      brokerURL: WSS_URL + "/ws-stomp/websocket",
      //webSocketFactory: () => new SockJS(SERVER_URL + "/api/ws-stomp"),
      connectHeaders: {
        roomId: roomId,
        userId: userId,
        username: username,
        company: company,
        token: isAuthenticated,
        device: userDevice
      },
      debug: function (str) {
        // console.log(str);
      },
      reconnectDelay: 5000, //자동 재 연결
      heartbeatIncoming: 6000,
      heartbeatOutgoing: 6000,
      onConnect: () => {
        if (client.current != null) {
          const chatSignal = (e) => {
            const body = JSON.parse(e.body);

            switch (body.type) {
              case "USER_ENTER":
                /* 관리자에게 현재 입장중인 유저 수 & 정보를 사용자 입장 할 때마다 전송 */
                if(isAuthenticated) {
                  client.current.publish({
                    destination: '/pub/message/send',
                    headers: {
                      token: isAuthenticated
                    },
                    body: JSON.stringify({
                      type: 'USER_COUNT',
                      roomId: roomId
                    })
                  });
                }
                break;
              case "TALK":
              case "ANSWER":
                setChatting((_setChatting) => [..._setChatting, body]);
                break;
              case "PUBLISH_CLEAR":
                setChatting([]);
                break;
              case "NOTICE_SAVE":
                setNotice({
                  id: body.id,
                  message: body.message
                });
                break;
              case "ANSWER_REMOVE":
              case "PUBLISH_REMOVE":
                // setChatting(chatting.filter((user) => user.id !== body.id));
                setChatting((_setChatting) => _setChatting.filter(
                    (user) => user.id !== body.id));
                break;
              case "USER_COUNT":
                setUserCount(body.userCount);
                break;
              case "IP_BLOCK":
                if (body.ip === chatroom.ip) {
                  disConnect();
                }
                break;
              default:
                break;
            }
          }

          /* 채팅방 입장 */
          client.current.subscribe(
              "/sub/message/send/" + roomId,
              chatSignal,
              {
                roomId: roomId,
                userId: userId,
                username: username,
                company: company,
                device: userDevice,
                token: isAuthenticated,
                ip: chatroom.ip
              }
          );

          /* 채팅방 입장 정보를 관리자에게 전송 */
          client.current.publish({
            destination: '/pub/message/send',
            headers: {
              token: isAuthenticated
            },
            body: JSON.stringify({
              type: 'USER_ENTER',
              roomId: roomId
            })
          });
        }
      },
      onStompError: (frame) => {
        console.log('Broker reported error: ' + frame.headers['message']);
        console.log('Additional details: ' + frame.body);
      }
    });

    if (typeof WebSocket !== 'function') {
      client.current.webSocketFactory = function () {
        return new SockJS(SERVER_URL + "/api/ws-stomp");
      };
    }

    client.current.activate();
  }, [chatroom, company, isAuthenticated, roomId, userDevice, userId, username]);

  useEffect(() => {
    if (isSuccess) {
      setUserDevice(chatroom.device);
      if (chatroom.notice) {
        setNotice({
          "id": chatroom.notice.id,
          "message": chatroom.notice.message
        });
      }
      setChatting(chatroom.messageList);
      setNowTime(dayjs());
      setEndTime(dayjs(chatroom.endTime));

      scrollToBottom();
    }
  }, [chatroom, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      if (roomId !== null && userId !== null && username !== null && (userDevice
          !== null && userDevice !== undefined)) {
        connect();
      }
    }
  }, [connect, isSuccess, roomId, userDevice, userId, username]);

  /* 채팅 변경 후 동작 */
  useEffect(() => {
    scrollToBottom();
  }, [chatting]);

  const disConnect = () => {
    if (client.current != null) {
      if (client.current.connected) {
        client.current.deactivate();
        setChatting((_setChatting) => [..._setChatting, {
          id: 0,
          message: "행사가 종료되었습니다.",
          type: "END"
        }]);
      }
    }
  };

  /* 일반 메세지 */
  const onPublish = (message: String) => {
    if (!client.current.connected) {
      return;
    }

    client.current.publish({
      destination: '/pub/message/send',
      headers: {
        token: isAuthenticated
      },
      body: JSON.stringify({
        type: 'TALK',
        roomId: roomId,
        userId: userId,
        username: username,
        company: company,
        message: message
      })
    });
  }

  /* 답변 메세지 */
  const onAnswer = (message: String, id) => {
    if (!client.current.connected) {
      return;
    }

    client.current.publish({
      destination: '/pub/message/send',
      headers: {
        token: isAuthenticated
      },
      body: JSON.stringify({
        type: 'ANSWER',
        id: id,
        roomId: roomId,
        userId: userId,
        username: username,
        message: message
      })
    });
  }

  /* 메세지 초기화 */
  const onClear = () => {
    if (!client.current.connected) {
      return;
    }
    client.current.publish({
      destination: '/pub/message/send',
      headers: {
        token: isAuthenticated
      },
      body: JSON.stringify({
        type: 'PUBLISH_CLEAR',
        roomId: roomId
      })
    });
  }

  /* 공지사항 변경 */
  const onNotice = (message) => {
    if (!client.current.connected) {
      return;
    }
    client.current.publish({
      destination: '/pub/message/send',
      headers: {
        token: isAuthenticated
      },
      body: JSON.stringify({
        type: 'NOTICE_SAVE',
        roomId: roomId,
        id: notice.id,
        message: message,
      })
    });
  }

  /* 답변 삭제 */
  const onAnswerRemove = (id) => {
    if (!client.current.connected) {
      return;
    }

    client.current.publish({
      destination: '/pub/message/send',
      headers: {
        token: isAuthenticated
      },
      body: JSON.stringify({
        type: 'ANSWER_REMOVE',
        roomId: roomId,
        id: id
      })
    });
  }

  /* 메세지 삭제 */
  const onPublishRemove = (id) => {
    if (!client.current.connected) {
      return;
    }

    client.current.publish({
      destination: '/pub/message/send',
      headers: {
        token: isAuthenticated
      },
      body: JSON.stringify({
        type: 'PUBLISH_REMOVE',
        roomId: roomId,
        id: id
      })
    });
  }

  /* 실시간 접속자 정보 */
  const onChatInfo = async () => {
    const response =  await api.get(
        SERVER_URL + "/api/accessor/" + roomId,
        {
          headers: {
            authorization: isAuthenticated
          }
        }
    );

    /* 실시간 접속자 정보 확인과 동시에 접속인원 수 가져오기 */
    client.current.publish({
      destination: '/pub/message/send',
      headers: {
        token: isAuthenticated
      },
      body: JSON.stringify({
        type: 'USER_COUNT',
        roomId: roomId
      })
    });

    setUserEnterList(response.data);
  }

  /* IP Block */
  const onDisConnect = (userIp) => {
    if (!client.current.connected) {
      return;
    }

    client.current.publish({
      destination: '/pub/message/send',
      headers: {
        token: isAuthenticated,
        ip: userIp
      },
      body: JSON.stringify({
        type: 'IP_BLOCK',
        roomId: roomId
      })
    });
  }

  /* 일반 함수 */
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const onReplace = (content) => {
    return content.replace(urlRegex, function (url) {
      return '<a href="' + url
          + '" target="_blank" style="background-color: #fff;padding: 3px 8px;border-radius: 2px;color: #333;margin-left: 3px;display: inline-block;word-break: break-all;">'
          + url + '</a>';
    });
  }

  /* 스크롤 하단 이동 */
  const scrollToBottom = () => {
    if (scrollRef.current) {
      // scrollRef.current.scrollIntoView(
      //     {behavior: 'smooth', block: 'end', inline: 'nearest'}
      // );

      elementScrollIntoView(scrollRef.current, {
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  useInterval(() => {
    if (client.current != null) {
      if (client.current.connected) {
        setNowTime(dayjs());

        if (nowTime.diff(endTime) >= 0) {
          disConnect();
        }
      }
    }
  }, 60000);

  return (
      <MotionBase>
        {
          isSuccess ?
              <Container
                  theme={{backgroundColor: chatroom.theme.footerBgColor}}>
                <Wrapper>
                  <MessageContainer
                      theme={{backgroundColor: chatroom.theme.backgroundColor}}
                  >
                    <Notice
                        theme={{
                          noticeBgColor: chatroom.theme.noticeBgColor,
                          noticeColor: chatroom.theme.noticeColor,
                          noticeSize: chatroom.theme.noticeSize
                        }}
                        notice={notice}
                        onNotice={onNotice}
                        onReplace={onReplace}
                        isAuthenticated={isAuthenticated}
                    />
                    <MessageWrapper ref={scrollRef}>
                      {
                        chatting.map(
                            item =>
                                <Chatting
                                    key={item.id}
                                    item={item}
                                    theme={{
                                      timeColor: chatroom.theme.timeColor,
                                      timeSize: chatroom.theme.timeSize,
                                      nicknameColor: chatroom.theme.nicknameColor,
                                      nicknameSize: chatroom.theme.nicknameSize,
                                      messageColor: chatroom.theme.messageColor,
                                      messageSize: chatroom.theme.messageSize,
                                      answerColor: chatroom.theme.answerColor,
                                      answerBgColor: chatroom.theme.answerBgColor,
                                      adminNicknameColor: chatroom.theme.adminNicknameColor,
                                      adminMessageColor: chatroom.theme.adminMessageColor
                                    }}
                                    userId={userId}
                                    onReplace={onReplace}
                                    onAnswer={onAnswer}
                                    onPublishRemove={onPublishRemove}
                                    onAnswerRemove={onAnswerRemove}
                                    isAuthenticated={isAuthenticated}
                                    fileStorage={ item.userId !=0 ?  null : chatroom.theme.fileStorage}/>
                        )
                      }
                    </MessageWrapper>
                  </MessageContainer>
                  <MessageInputContainer
                      theme={{
                        backgroundColor: chatroom.theme.messageLineColor,
                      }}>
                    <Publish
                        theme={{
                          sendBtnBgColor: chatroom.theme.sendBtnBgColor,
                          sendBtnColor: chatroom.theme.sendBtnColor
                        }}
                        onPublish={onPublish}
                    />
                  </MessageInputContainer>
                </Wrapper>
                {
                  isAuthenticated ?
                      <Box>
                        <ClearChatting onClear={onClear}/>
                        <ChattingInfo
                            onChatInfo={onChatInfo}
                            onDisConnect={onDisConnect}
                            userEnterList={userEnterList}
                            userCount={userCount}
                            roomId={roomId}
                        />
                      </Box>
                      :
                      null
                }
                <BusinessLink
                    theme={{
                      fontColor: chatroom.theme.businessColor,
                    }}
                    href={chatroom.theme.businessLink}
                    target={"_blank"}
                    underline="none">
                  {chatroom.theme.businessText}
                </BusinessLink>
              </Container>
              :
              <CenterBox sx={{p: 2}}>
                <CircularProgress sx={{mb: 3}}/>
                <Typography variant="h6" component="h2" sx={{mb: 1}}>
                  채팅방에 연결중입니다...
                </Typography>
                <Box>
                  같은 화면이 반복된다면 새로고침 해주시기 바랍니다.
                </Box>
              </CenterBox>
        }
      </MotionBase>
  );
}

export default ChatRoomEnter;
